import styled from 'styled-components';

export const defaultColor = '#ea8e0a';

export const TitlePage = styled.h1`
    font-size: 48px;
    font-weight: 200;
    color: #000;

    span {
        font-weight: bold;
        color: ${defaultColor};
    }
`;

export const PageBox = styled.div`
    padding: 40px 0;

    .mt-30 {
        margin-top: 30px;
    }

    .mb-30 {
        margin-bottom: 30px;
    }
`;

export const ButtonWhatsapp = styled.a`
    position: fixed;
    font-size: 30px;
    bottom: 12px;
    left: 15px;
    cursor: pointer;
    z-index: 3;

    i {
        background-color: #4dc247;
        border-radius: 100%;
        padding: 8px;
        color: #FFF;
    }
`;

export const ButtonPhone = styled.a`
    position: fixed;
    font-size: 30px;
    bottom: 12px;
    left: 70px;
    cursor: pointer;
    z-index: 3;

    i {
        background-color: rgba(25,173,216,1);
        border-radius: 100%;
        padding: 8px;
        color: #FFF;
    }
`;

export const Separator = styled.img`
    width: 100%;
    &.flip {
        transform: scaleX(-1);
    }
`;
