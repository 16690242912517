import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import { useRef } from 'react';

import Header from './components/header';
import Footer from './components/footer';
import Banner from './components/Banner';

import Servicos from './pages/Servicos';
import QuemSomos from './pages/QuemSomos';
import Galeria from './pages/Galeria';
import Contato from './pages/Contato';

import { animateScroll as scroll } from 'react-scroll';

import { MenuKey } from './constants/menu';

import { contatoInfo } from './constants/Contato';

import { ButtonWhatsapp, ButtonPhone, Separator } from './styles';

import imgSeparador from'./assets/imgs/separador.png';

function App() {

  const pageQuemSomos = useRef<any>(null);
  const pageServicos = useRef<any>(null);
  const pageGaleria = useRef<any>(null);
  const pageContato = useRef<any>(null);

  const offsetHeader: number = 100;

  const sendToPage = ( page: number) => {

    let offset = 0;

    switch( page ) {

      case MenuKey.quemSomos: 
        offset = pageQuemSomos.current.offsetTop;
        break;

      case MenuKey.servicos:
        offset = pageServicos.current.offsetTop;
        break;

      case MenuKey.galeria:
        offset = pageGaleria.current.offsetTop;
        break;

      case MenuKey.contato:
        offset = pageContato.current.offsetTop;
        break;

    }

    scroll.scrollTo(offset - offsetHeader);
    
  }

  return (
    <div className="App">

      <Header sendToPage={sendToPage} />

      <Banner />

      <div className="body-pages">

        <div className="page" ref={pageQuemSomos}>
          <QuemSomos />
        </div>

        <Separator src={imgSeparador} />

        <div className="page" ref={pageServicos}>
          <Servicos />
        </div>

        <Separator src={imgSeparador} className="flip" />

        <div className="page" ref={pageGaleria}>
          <Galeria />
        </div>

        <div className="page" ref={pageContato}>
          <Contato />
        </div>

      </div>

      <Footer />

      <ButtonWhatsapp href={contatoInfo.whatsappButton} target="_blank">
        <i className="fa fa-whatsapp iconGreen"></i>
      </ButtonWhatsapp>

      <ButtonPhone href={`tel:${contatoInfo.telefoneButton}`} target="_blank">
        <i className="fa fa-phone"></i>
      </ButtonPhone>

    </div>
  );
}

export default App;
