
import { TitlePage } from '../../styles';
import { PageQuemSomos, BoxTexto } from './styles';

import { Container, Row, Col } from 'react-bootstrap';

import imgEntregador from'../../assets/imgs/entrega.png';

import ScrollAnimation from 'react-animate-on-scroll';

const QuemSomos = () => {

    return (
        <PageQuemSomos>
            
            <Container>

                <Row>
                    <Col md={6} className="flexContainer mb-30">
                        <ScrollAnimation animateIn='bounceInLeft' animateOnce={true}>
                            <img src={imgEntregador}></img>
                        </ScrollAnimation>
                    </Col>

                    <Col md={6} className="flexContainer">
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                            <BoxTexto>
                                <TitlePage><span>Quem</span> Somos</TitlePage>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Commodo odio aenean sed adipiscing diam donec adipiscing tristique risus. Scelerisque in dictum non consectetur. In nisl nisi scelerisque eu ultrices vitae auctor eu augue. Cursus risus at ultrices mi tempus imperdiet. Senectus et netus et malesuada. Cursus vitae congue mauris rhoncus aenean vel elit scelerisque. Nibh tortor id aliquet lectus proin nibh nisl condimentum id. Imperdiet proin fermentum leo vel orci porta non pulvinar neque. Euismod quis viverra nibh cras pulvinar. Consectetur adipiscing elit ut aliquam. Cras semper auctor neque vitae tempus quam pellentesque. Arcu non odio euismod lacinia at. Est ante in nibh mauris cursus. Vitae nunc sed velit dignissim sodales ut eu. Mattis molestie a iaculis at erat. In est ante in nibh mauris. Euismod elementum nisi quis eleifend quam adipiscing vitae proin. Sem nulla pharetra diam sit amet. Ut etiam sit amet nisl purus in mollis nunc.</p>

                            </BoxTexto>
                        </ScrollAnimation>
                    </Col>

                </Row>

            </Container>
            
        </PageQuemSomos>
    )
}

export default QuemSomos;