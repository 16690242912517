import styled from 'styled-components';

export const BoxFooter = styled.div`
    background: #585858;
    padding: 10px;

    p {
        margin: 0;
        color: #FFF;
    }

    span {
        color: #ff6500;
        font-weight: bold;
    }
`;