
import axios from 'axios';
import { useEffect, useState } from 'react';

const Galeria = () => {

    const [ images, setImages ] = useState([]);

    const getImages = async () => {
        const response =
        await axios.get('https://www.instagram.com/sunsetwithbubbles/', {
            headers: {                  
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Authorization", 
                "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE" ,
                "Content-Type": "application/json;charset=UTF-8"                   
            },
        });
        console.log(response.data);
    }

    useEffect( () => {
        getImages();
    }, [getImages])

    /*
    const images = [
        'https://static.wixstatic.com/media/3ea6ae_5d57b8a1d2f44d39be32546f4b5cb913~mv2.jpg/v1/fit/w_2500,h_1330,al_c/3ea6ae_5d57b8a1d2f44d39be32546f4b5cb913~mv2.jpg',
        'https://http2.mlstatic.com/box-para-banheiro-D_NQ_NP_427201-MLB20303069761_052015-F.jpg',
        'https://http2.mlstatic.com/box-para-banheiro-D_NQ_NP_427201-MLB20303069761_052015-F.jpg',
        'https://http2.mlstatic.com/box-para-banheiro-D_NQ_NP_427201-MLB20303069761_052015-F.jpg',
        'https://http2.mlstatic.com/box-para-banheiro-D_NQ_NP_427201-MLB20303069761_052015-F.jpg',
        'https://http2.mlstatic.com/box-para-banheiro-D_NQ_NP_427201-MLB20303069761_052015-F.jpg',
        'https://http2.mlstatic.com/box-para-banheiro-D_NQ_NP_427201-MLB20303069761_052015-F.jpg'
    ]
*/

     return (
         <>
            
         </>
     )
 }

 export default Galeria;