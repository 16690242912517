import styled from 'styled-components';

import { PageBox } from '../../styles';

export const PageContato = styled(PageBox)`
    position: relative;

    h2 {
        font-size: 24px;
        margin: 30px 0 20px;
        font-weight: 400;
        padding-bottom: 14px;
        border-bottom: 1px solid #ea8e0a;
        color: #ea8e0a;
    }

    a {
        color: #000;
        transition: all 0.5s;

        &:hover {
            text-decoration: none;
            color: #ff6500;
            border-bottom: 1px solid #ff6500;
        }
    }

    i {
        margin-right: 10px;
        color: #ea8e0a;

        &.clock {
            font-size: 40px;
            margin-bottom: 10px;
        }
    }
`;