
import { TitlePage } from '../../styles';
import { PageContato } from './styles';

import { Container, Row, Col } from 'react-bootstrap';

import { contatoInfo } from '../../constants/Contato';

const Contato = () => {

    return (
        <PageContato>

            <TitlePage><span>Contato</span></TitlePage>
            
            <Container>

                <Row>
                    <Col sm={12} md={4}>
                        <h2>Fale Conosco</h2>
                        <p><i className="fa fa-phone"></i>{contatoInfo.telefone}</p>
                        <p><i className="fa fa-whatsapp"></i>{contatoInfo.celular}</p>
                        <p><a href={`mailto:${contatoInfo.email}`}><i className="fa fa-envelope"></i>{contatoInfo.email}</a></p>
                    </Col>

                    <Col sm={12} md={4}>
                        <h2>Nos siga nas redes sociais</h2>
                        <p><a href={`https://${contatoInfo.instagram}`} target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i>{contatoInfo.instagram}</a></p>
                        <p><a href={`https://${contatoInfo.facebook}`} target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i>{contatoInfo.facebook}</a></p>
                    </Col>

                    <Col sm={12} md={4}>
                        <h2>Horário de atendimento</h2>
                        <i className="fa fa-clock-o clock" aria-hidden="true"></i>
                        <p>De segunda a sexta, <br /> das 8h às 18h</p>
                    </Col>
                </Row>
                    
            </Container>
            
        </PageContato>
    )
}

export default Contato;