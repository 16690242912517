
import { useState, useMemo } from 'react';

import { BoxHeader, BoxFrom, TextInfo, BoxMenu, Logo, MenuContainer, MenuItem, LogoFloat, BoxMenuMobile } from './styles';

import { Container, Row, Col  } from 'react-bootstrap';

import { MenuKey } from '../../constants/menu';

import LogoImg  from '../../assets/logo_ecoglass_horizontal.png';
import LogoSimples  from '../../assets/logo_simples.png';

import { contatoInfo } from '../../constants/Contato';

const Header = ({
    sendToPage
}) => {

    const [ mobileMenu, setMobileMenu ] = useState(false);

    const goToPage = (page: number) => {
        sendToPage(page);
        setMobileMenu(false);
    } 

    const menuItens = useMemo( () => {
        return(
            <>
                <MenuItem onClick={ () => goToPage(MenuKey.quemSomos) }>Quem somos</MenuItem>
                <MenuItem onClick={ () => goToPage(MenuKey.servicos) }>Serviços</MenuItem>
                <MenuItem onClick={ () => goToPage(MenuKey.galeria) }>Galeria</MenuItem>
                <MenuItem onClick={ () => goToPage(MenuKey.contato) }>Contato</MenuItem>
            </>
        )
    }, [] )

    return (
        <>
            <BoxHeader>

                <LogoFloat>
                    <Logo src={LogoImg} />
                </LogoFloat>

                <BoxFrom>
                    <Container>
                        <Row>
                            <Col xs={6} md={4}>
                                <TextInfo><i className="fa fa-envelope"></i>{contatoInfo.email}</TextInfo>
                            </Col>
                            <Col xs={6} md={4}>
                                <TextInfo><i className="fa fa-phone"></i>{contatoInfo.telefone}</TextInfo>
                            </Col>
                            <Col xs={12} md={4}>
                                <TextInfo className="whatsappText"><i className="fa fa-whatsapp"></i>{contatoInfo.celular}</TextInfo>
                            </Col>
                        </Row>
                    </Container>
                </BoxFrom>
                
                <BoxMenu className="d-none d-sm-block">

                    <Container>
                        <Row>
                            <Col md={12}>
                                <MenuContainer>
                                    {menuItens}
                                </MenuContainer>
                            </Col>
                        </Row>
                    </Container>

                </BoxMenu>

            </BoxHeader>

            <BoxMenuMobile className="d-block d-sm-none">
                <Container>
                    <Row>
                        <Col xs={6}>
                            <img src={LogoImg} />
                        </Col>
                        <Col xs={6} className="d-flex">
                            <i className={`fa fa-${mobileMenu ? 'times' : 'bars'}`} onClick={ () => setMobileMenu(!mobileMenu) }></i>
                        </Col>
                    </Row>
                </Container>

                <Container className={`menuMobile ${mobileMenu ? "active" : ""}`}>
                    <Row>
                        <Col xs={12}>
                            <MenuContainer className="mobile">
                                {menuItens}
                            </MenuContainer>
                        </Col>
                    </Row>
                </Container>
            </BoxMenuMobile>
        </>
    )
}

export default Header;