import styled from 'styled-components';

export const BoxItem = styled.div`
    margin-top: 20px;
    width: 100%;
    position: relative;
    background-color: #FFF;
    border: 8px solid #ddd;
    height: 270px;
    transition: all 0.5s;
    cursor: pointer;

    &:hover {
        box-shadow: 4px 3px 8px 1px #969696;
    }

    img {
        width: 100%;
        height: 100%;
    }

    @media(min-width: 1200px) {
        width: 90%;
    }

    @media(max-width: 780px) {
        height: 200px;
    }
`;

export const TitleServico = styled.div`
    position: absolute;
    width: 100%;
    background: rgba(255, 101, 0, 0.6);
    bottom: 0;
    padding: 8px;
    color: #FFF;
    font-weight: bold;
`;
