
import {
    Modal
} from "react-bootstrap";

import Carousel from 'react-bootstrap/Carousel';

const ModalGaleria = ({
    show,
    handleClose,
    servico
}) => {

    return (

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{servico.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Carousel>

                { servico?.imgs && servico.imgs.map( img => (
                    <Carousel.Item key={img}>
                        <img
                            className="d-block w-100"
                            src={img}
                        />
                    </Carousel.Item>
                ) ) }
                
            </Carousel>
        </Modal.Body>
      </Modal>

    )
}

export default ModalGaleria;