
import React from 'react';

import { ServicoInterface } from '../../../../constants/Servicos';

import { Col } from 'react-bootstrap';

import { BoxItem, TitleServico } from './styles';

interface ItemServicosProps {
    servico: ServicoInterface;
    openModal: any;
}

const ItemServico: React.FC<ItemServicosProps> = ({ 
    servico,
    openModal
}) => {

    return (
        <Col xs={6} sm={6} md={4} className="mt-30">
            <BoxItem onClick={() => openModal(servico)}>
                <img src={servico.imgPrincipal} />
                <TitleServico>{servico.name}</TitleServico>
            </BoxItem>
        </Col>
    )

}

export default ItemServico;