import styled from 'styled-components';

export const BoxHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 20;
    box-shadow: 0 4px 2px -2px gray;

    @media(max-width: 780px) {
        position: relative;
    }
`;

export const TextInfo = styled.div`
    font-weight: bold;
    color: #FFF;
    font-family: "Fira Sans", Sans-serif;
    font-size: 12px;
    cursor: pointer;

    i {
        margin-right: 10px;
    }

    @media(max-width: 780px) {
        font-size: 11px;
        &.whatsappText {
            margin-top: 10px;
        }
    }
`;

export const BoxMenu = styled.div`
    background-color: #fff;
    padding: 12px 0;
`;

export const BoxFrom = styled.div`
    background-color: #ea8e0a;
    padding: 8px 0;
`;

export const MenuContainer = styled.ul`
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-around;
    padding-right: 4px;
    padding-left: 10px;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    padding: 0 40px;

    &.mobile {
        display: block;

        li {
            margin-top: 12px;
        }
    }
`;

export const MenuItem = styled.li`
    font-size: 22px;
    color: #000;
    cursor: pointer;
    transition: all 0.5s;
    border-bottom: 1px solid transparent;

    &:hover {
        color: #ff6500;
        border-bottom: 1px solid #ff6500;
    }

`;

export const Logo = styled.img`
    position: absolute;
    right: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
`;

export const LogoFloat = styled.div`
    position: absolute;
    background-color: #fff;
    width: 13%;
    height: 120%;
    border-bottom-right-radius: 45px;
    border-top: none;
    border-left: none;
    box-shadow: 0 4px 2px -2px grey;
    
    @media(max-width: 800px) {
        display: none;
    }
`;

export const BoxMenuMobile = styled.div`
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 20;

    .container {
        box-shadow: 0 4px 2px -2px gray;
    }

    .menuMobile {
        height: 0;
        overflow: hidden;
        transition: all 0.5s;
        position: absolute;
        background-color: #fff;
        box-shadow: 0 4px 2px -2px gray;

        &.active {
            border-top: 1px solid #ea8e0a;
            height: 230px;
        }
    }

    img { width: 150px; }
    i {
        margin: auto;
        font-size: 30px;
        margin-right: 20px;
        cursor: pointer;
        color: #FF9933;
    }
`;