import styled from 'styled-components';

import { PageBox } from '../../styles';

export const PageQuemSomos = styled(PageBox)`
    position: relative;
    margin-top: 20px;

    .flexContainer {
        display: flex;
        align-items: center;

        img { width: 100%; }
    }
`;

export const BoxTexto = styled.div`
    p {
        padding-top: 20px;
        font-size: 14px;
        text-align: left;
    }
`;