
import { BoxFooter } from './styles';

const Footer = () => {

    return (
        <BoxFooter>
            <p><span>Ecoglass</span> – Todos os direitos reservados</p>
            <p>Desenvolvido por <span>Marcus Felet</span></p>
        </BoxFooter>
    )
}

export default Footer;