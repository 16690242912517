
import { BannerBox, VideoMask, TextFloat } from './styles';

import Video from '../../assets/video.mp4';

const Banner = () => {

    return (
        <BannerBox>

            <VideoMask>
                <TextFloat>
                    <h1>ECO<span>GLASS</span></h1>
                    <p>Há mais de 6 anos <span>refletindo qualidade</span></p>
                </TextFloat>
            </VideoMask>

            <video autoPlay width="100%" muted loop playsInline  >
                <source src={Video} type="video/mp4"/>
            </video>

        </BannerBox>
    )
} 

export default Banner;