import styled from 'styled-components';

export const BannerBox = styled.div`
    position: relative;
    .carousel-item {
        height: 500px;
    }

    .carousel-caption {
        background: rgba(25, 173, 216,0.8);
        right: 50%;
        margin: auto;
        transform: translateX(50%);
        padding: 30px;
        left: auto;
    }

`;

export const VideoMask = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(247,210,157,0.5);
    z-index: 2;
`;

export const TextFloat = styled.div`
    position: absolute;
    right: 20%;
    top: 30%;
    
    h1 {
        font-size: 68px;
        font-weight: 900;
        font-family: Arial;   
        margin-bottom: 0;
        text-align: left;
        span { color: #FF9933; }
    }

    p {
        font-size: 28px;
        font-family: Arial;
        margin-bottom: 0;
        text-align: left;
        span {
            color: #fff; 
            background: #FF9933; 
        }
    }

    @media(max-width: 780px) {
        right: 10%;
        h1 { font-size: 48px; }
        p { font-size: 18px; }
    }
`;