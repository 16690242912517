
export interface ServicoInterface {
    name: string;
    imgPrincipal: string;
    imgs: string[];
}

export const servicos : ServicoInterface[] = [
    {
        name: "Box", 
        imgPrincipal: 'https://http2.mlstatic.com/box-para-banheiro-D_NQ_NP_427201-MLB20303069761_052015-F.jpg', 
        imgs: [
            'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1', 
            'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1'
        ] 
    },
    {
        name: "Janelas", 
        imgPrincipal: 'https://http2.mlstatic.com/box-para-banheiro-D_NQ_NP_427201-MLB20303069761_052015-F.jpg', 
        imgs: [
            'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1', 
            'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1'
        ] 
    },
    {
        name: "Teste 1", 
        imgPrincipal: 'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1', 
        imgs: [
            'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1', 
            'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1'
        ] 
    },
    {
        name: "Teste 1", 
        imgPrincipal: 'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1', 
        imgs: [
            'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1', 
            'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1'
        ] 
    },
    {
        name: "Teste 1", 
        imgPrincipal: 'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1', 
        imgs: [
            'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1', 
            'https://th.bing.com/th/id/OIP.TucT-XiBjrurs-BGFY5SKAHaHa?pid=ImgDet&rs=1'
        ] 
    },
]
