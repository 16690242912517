
import React, { useState } from 'react';

import { TitlePage } from '../../styles';
import { PageServicos } from './styles';

import { Container, Row } from 'react-bootstrap';

import ItemServico from './components/ItemServico';
import ModalGaleria from './components/ModalGaleria';

import ScrollAnimation from 'react-animate-on-scroll';

import { ServicoInterface, servicos } from '../../constants/Servicos';

const Servicos = () => {

    const [showGallery, setShowGallery] = useState(false);
    const [servicoSelected, setServicoSelected] = useState<ServicoInterface>(
        {} as ServicoInterface)

    const handleClose = () => setShowGallery(false);
    const handleShow = () => setShowGallery(true);

    const openModal = ( servico: ServicoInterface) => {
        setServicoSelected(servico);
        handleShow();
    }

    return (
        <PageServicos>
            <TitlePage>Conheça nossos <span>Serviços</span></TitlePage>

            <Container>
                <ScrollAnimation animateIn='bounceInRight' animateOnce={true}>
                    <Row>
                    
                        { servicos.map( (serv, index) => (
                            <ItemServico servico={serv} key={`${serv.name} - ${index}`} openModal={openModal} />
                        ))}  
                    
                    </Row>
                </ScrollAnimation>
            </Container>

            <ModalGaleria 
                show={showGallery} 
                handleClose={handleClose}
                servico={servicoSelected} />

        </PageServicos>
    )
}

export default Servicos;